.header {
    padding-top: var(--mantine-spacing-sm);
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
    border-bottom: rem(1px) solid light-dark(var(--mantine-color-gray-2), transparent);
    margin-bottom: rem(120px);
    color: #fff;
    display: block;
    height: "50px";
    justify-content: space-between;
    align-items: center;
    
  }
  

  .mainSection {
    display: block;
    padding-bottom: var(--mantine-spacing-sm);
    overflow-y:visible;
    display: block;
    height: 50px;
  }
  
  .user {
    color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));
    padding: var(--mantine-spacing-xs) var(--mantine-spacing-sm);
    border-radius: var(--mantine-radius-sm);
    transition: background-color 100ms ease;
  
    &:hover {
      background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-8));
    }
  
    @media (max-width: $mantine-breakpoint-xs) {
      display: none;
    }
  }
  
  .userActive {
    background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-8));
  }
  
  .tabsList {
    &::before {
      display: none;
    }
  }
  
  .tab {
    font-weight: 500;
    height: rem(38);
    background-color: transparent;
    position: relative;
    bottom: -1px;
  
    &:hover {
      background-color: light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-5));
    }
  
    &[data-active] {
      background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-7));
      border-color: light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-7));
      border-bottom-color: transparent;
    }
  }

  .navbar {
    background-color: #333;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }