.Card {
    width: 60%;
    margin-left: 5%; 
  }
@media only screen and (max-width: 1000px) {
    .Card {
      margin-left: 0; /* Changer la marge pour les dispositifs mobiles selon vos besoins */
      width: 100%; /* Changer la largeur pour les dispositifs mobiles selon vos besoins */
    }
  }
  