/* Conteneur principal */
.root {
  background-color: var(--mantine-color-gray-0);
  border-radius: var(--mantine-radius-sm);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: var(--mantine-spacing-sm);
  max-height: 500px;
  overflow-y: auto;
  position: relative;
  font-size: 0.875rem;
}

/* Conteneur des liens */
.container {
  position: relative;
}

/* Style des liens */
.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--mantine-color-gray-7);
  line-height: 1.2;
  font-size: 0.875rem;
  padding: 5px 10px;
  height: var(--link-height);
  margin-bottom: 8px; /* Espacement vertical ajouté */
  border-radius: var(--mantine-radius-sm);
  transition: background-color 0.2s, color 0.2s;
}

.link:last-child {
  margin-bottom: 0; /* Supprime l'espacement pour le dernier élément */
}

.link:hover {
  background-color: var(--mantine-color-blue-light);
  color: var(--mantine-color-blue-dark);
}

/* Style du lien actif */
.linkActive {
  font-weight: 600;
  background-color: var(--mantine-color-blue-light);
  color: var(--mantine-color-blue-dark);
}

/* Indicateur actif */
.indicator {
  position: absolute;
  left: 0;
  width: 3px;
  height: var(--link-height);
  background-color: var(--mantine-color-blue-6);
  border-radius: 2px;
  transition: transform 0.2s ease-in-out;
  transform-origin: top left;
  transform: translateY(calc(var(--link-height) * var(--active-index)));
}
