.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}
.flexrow {
  display: flex;
  align-items: left;
  grid-gap: 10px;
}
.login-btn {
  border-radius: 4px;
  transition: all 0.3s;  
  box-shadow: rgb(0 0 0 / 45%) 0px 2px 4px 0px;
  width: 50px;
  height: 50px;
  outline: none;
  border: none;
  margin-right: 10px
  
  
}
.fb-btn {
  color: #fff;
  background-color: #4c69ba;
}
.gog-btn {
  color: #fff;
  background-color: #dd4b39;
}
.yah-btn {

  color: #fff;
  background-color: #720e9e;
}
.login .yah-btn {
  background-color: #720e9e;
}
.login .yah-btn i {
  color: white;
}
.login .yah-btn:hover {
  background-color: #fff;
}
.login .yah-btn:hover i {
  color: #720e9e
}
.login .gog-btn {
  background-color: #dd4b39
}
.login .gog-btn  i{
  color: #fff;
}
.gog-btn:hover {
  background-color: #fff;
}
.gog-btn:hover i {
  color: #dd4b39;
}
.fb-btn:hover {
  color:  #4c69ba;
  background-color: #fff;}
.login .fb-btn:hover i {
  color: #4c69ba
}
.login .fb-btn i {
  color: #fff;

}

.login .logincontainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 80%;
}

.login {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 400px;
  background-color: rgb(248, 248, 248);
  border-radius: 10px;
  box-shadow: 0px 0px 10px 1px rgb(214, 214, 214);
  padding: 20px;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  min-height: 60vh;
  grid-gap: 20px;
  max-width: 90%;
}

.alert {
  text-align: center;
  margin-bottom: 1em;
  font-size: 10px;
  color: rgb(240, 6, 6);
}

.flexflow {
  justify-content: left;
}
